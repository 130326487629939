import React from "react";
import { Product } from "./../api/Product/Product";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Button } from "react-bootstrap";
import { IconShoppingCartPlus } from "@tabler/icons-react";
import { useCartManagement } from "../hooks/useCartManagement";
import LoginRequiredModal from "../components/LoginRequiredModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export type Props = {
  product: Product;
};

export function ProductItem({ product }: Props) {
  const { id, name, productMedias, description, slogan, price, originalPrice } =
    product;
  const { addToCart, showLoginModal, setShowLoginModal } = useCartManagement();

  const handleAddToCart = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (product === undefined) {
      return;
    }
    try {
      await addToCart({ productId: id, quantity: 1 });
      toast.success("已加入購物車");
    } catch (error) {
      toast.error("加入購物車失敗");
    }
  };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={1000} />
      <Card className="h-100" style={{ borderRadius: "8px", padding: "16px" }}>
        <Card.Img
          variant="top"
          src={
            productMedias[0] && productMedias[0].media
              ? productMedias[0].media?.url
              : "https://placehold.co/300x180?text=Placeholder"
          }
          alt={name}
          style={{ height: "180px", objectFit: "cover" }}
        />
        <Card.Body className="d-flex flex-column justify-content-between p-0">
          <Card.Title className="fw-bold">{name}</Card.Title>
          <Card.Text>{slogan}</Card.Text>
          <Card.Text
            dangerouslySetInnerHTML={{ __html: description }}
          ></Card.Text>

          <div className="mt-auto">
            {/* Price Section */}
            <div className="d-flex justify-content-between align-items-end">
              <div>
                {originalPrice && (
                  <p
                    className={`mb-0 ${
                      price
                        ? "text-muted text-decoration-line-through"
                        : "text-danger fw-bold"
                    }`}
                  >
                    NT${originalPrice}
                  </p>
                )}
                {price && (
                  <p className="text-danger fw-bold fs-5 mb-0">NT${price}</p>
                )}
              </div>

              {/* Add to Cart Button */}
              <Button
                variant="outline-primary d-flex align-items-center"
                onClick={handleAddToCart}
              >
                <IconShoppingCartPlus size={20} />
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* 登入提示彈窗 */}
      <LoginRequiredModal
        show={showLoginModal}
        onClose={handleCloseLoginModal}
      />
    </>
  );
}
